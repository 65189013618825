import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    font-family: "proximanova";
    font-weight: normal;
    font-style: normal;
  }

  body {
    width: 100%;
    height: 100%;
    font-family: "proximanova", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body * {
    font-family: "proximanova", sans-serif;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    margin: 0;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;

export default GlobalStyle;
