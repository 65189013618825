import { css } from 'styled-components';
import { BREAK_POINTS, BREAK_POINTS_HEIGHT } from 'constants/AppConstants';

import type {
  DefaultTheme,
  Interpolation,
  FlattenInterpolation,
  ThemedStyledProps,
  CSSObject,
  InterpolationFunction,
} from 'styled-components';

const modifyColor = (color: string, amount: number) => {
  color = color.slice(1);
  const num = parseInt(color, 16);

  let red = (num >> 16) + amount;
  if (red > 255) red = 255;
  else if (red < 0) red = 0;

  let blue = ((num >> 8) & 0x00ff) + amount;
  if (blue > 255) blue = 255;
  else if (blue < 0) blue = 0;

  let green = (num & 0x0000ff) + amount;
  if (green > 255) green = 255;
  else if (green < 0) green = 0;

  return `#${(green | (blue << 8) | (red << 16)).toString(16)}`;
};

export const lighten = (color: string, amount: number) =>
  modifyColor(color, amount);

export const darken = (color: string, amount: number) =>
  modifyColor(color, -amount);

export const splitColor = (color: string) => {
  const colorSplit = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  const splittedColor = [
    parseInt(colorSplit ? colorSplit[1] : '0', 16).toString(),
    parseInt(colorSplit ? colorSplit[2] : '0', 16).toString(),
    parseInt(colorSplit ? colorSplit[3] : '0', 16).toString(),
  ];
  return splittedColor;
};

export const opaque = (color: string, opacity: number) => {
  const [red, green, blue] = splitColor(color);
  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};

export const assignIfDefined =
  <TProps extends Record<string, any> = Record<string, any>>(
    prop: keyof TProps,
    cssAttribute: string = prop as string,
  ) =>
  (props: TProps) =>
    props[prop] !== undefined ? `${cssAttribute}: ${props[prop]};` : '';

export const assignIfExists =
  <TProps extends Record<string, any> = Record<string, any>>(
    prop: keyof TProps,
    cssAttribute: string = prop as string,
  ) =>
  (props: TProps) =>
    props[prop] !== undefined && props[prop] !== null
      ? `${cssAttribute}: ${props[prop]};`
      : '';

/*
  USAGE:
  import mediaQuery from 'styles/Utils';

  const StyledComponent = styled.div<{height:number}>`
    ${mediaQuery.mobile`
      background: blue;
      height: ${({ height }) => height}px;
    `}
    ${mediaQuery.desktop`
      background: blue;
      height: ${({ height }) => height}px;
    `}
    ${mediaQuery.small`
      background: blue;
      height: ${({ height }) => height}px;
    `}
  `;
*/
type MediaQueryFunctionType = <TProps extends Record<string, unknown>>(
  first:
    | TemplateStringsArray
    | CSSObject
    | InterpolationFunction<ThemedStyledProps<TProps, DefaultTheme>>,
  ...args: Array<Interpolation<ThemedStyledProps<TProps, DefaultTheme>>>
) => FlattenInterpolation<ThemedStyledProps<TProps, DefaultTheme>>;

const breakPointMediaQueries = Object.entries(BREAK_POINTS).reduce<
  Record<string, MediaQueryFunctionType>
>((acc, [size, pixelValue]) => {
  acc[size] = (first, ...args) => css`
    @media (min-width: ${pixelValue}px) {
      ${css(first, ...args)};
    }
  `;
  acc[`${size}MaxWidth`] = (first, ...args) => css`
    @media (max-width: ${pixelValue}px) {
      ${css(first, ...args)};
    }
  `;
  return acc;
}, {});

export const mediaQuery: Record<string, MediaQueryFunctionType> = {
  ...breakPointMediaQueries,
  mobile: (first, ...args) => css`
    @media (max-width: ${BREAK_POINTS.medium - 1}px) {
      ${css(first, ...args)};
    }
  `,
  desktop: (first, ...args) => css`
    @media (min-width: ${BREAK_POINTS.medium}px) {
      ${css(first, ...args)};
    }
  `,
  desktopBigHeight: (first, ...args) => css`
    @media (min-width: ${BREAK_POINTS.medium}px) and (min-height: ${BREAK_POINTS_HEIGHT.medium}px) {
      ${css(first, ...args)};
    }
  `,
};
