import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* Types */
import type { AppState } from 'app/store';

type StateType = {
  experimentsMapping: {
    [ExperimentName: string]: string;
  };
  deciderKeyMapping: {
    [DeciderKey: string]: boolean;
  };
};

const initialState: StateType = {
  experimentsMapping: {},
  deciderKeyMapping: {},
};

export const experimentSlice = createSlice({
  name: 'experiment',
  initialState,
  reducers: {
    setExperiments: (
      state,
      action: PayloadAction<{
        [ExperimentName: string]: string;
      }>,
    ) => {
      state.experimentsMapping = action.payload || {};
    },
    setDeciderKeys: (
      state,
      action: PayloadAction<{
        [DeciderKey: string]: boolean;
      }>,
    ) => {
      state.deciderKeyMapping = action.payload || {};
    },
  },
});

// Actions
export const { setExperiments, setDeciderKeys } = experimentSlice.actions;

// Selectors
export const getExperiment = (state: AppState, experimentName: string) =>
  state.experiment.experimentsMapping[experimentName];

export const getShowExperiment = (state: AppState, experimentName: string) =>
  getExperiment(state, experimentName) === 'show';

export const getAllExperiments = (state: AppState) =>
  state.experiment.experimentsMapping;

export const getDeciderKeyResult = (state: AppState, deciderKey: string) => {
  if (deciderKey in state.experiment.deciderKeyMapping) {
    return state.experiment.deciderKeyMapping[deciderKey];
  }
  return false;
};

// Reducer
const experimentReducer = experimentSlice.reducer;
export default experimentReducer;
