import { Suspense, useEffect, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { LegoProvider } from '@ContextLogic/lego';

/* Child Components */
import ErrorBoundary from 'components/common/ErrorBoundary';
import WishDotLoaderModal from 'components/common/DotLoaderModal';

/* Actions */
import { setFormattedSignupBonusAmount as setFormattedSignupBonusAmountAction } from 'app/marketingSlice';

/* Selectors */
import { getLocale } from 'app/appSlice';
import { getSignupBonusID } from 'app/marketingSlice';

/* Constants */
import {
  LANDING_PAGE_ROUTE,
  SIGNUP_PAGE_ROUTE,
  SINGLE_SIGNUP_PAGE_ROUTE,
  COMMON_HELP_ROUTES,
} from 'constants/RouteConstants';

/* Types */
import type { AppState, AppDispatch } from 'app/store';

/* Services */
import GetFormattedSignupBonusService from 'services/GetFormattedSignupBonus';

/* Lazy Imports */
const LandingPage = lazy(() => import('components/landing/LandingPage'));
const MultiScreenSignupPage = lazy(() =>
  import('components/signup/multiscreen/MultiScreenSignupPage'),
);
const SinglePageSignup = lazy(() =>
  import('components/signup/singlepage/SinglePageSignup'),
);
const HelpPage = lazy(() => import('components/helpPage/HelpPage'));
const NoMatchRedirect = lazy(() => import('components/common/NoMatchRedirect'));

const mapStateToProps = (state: AppState) => ({
  locale: getLocale(state),
  signupBonusID: getSignupBonusID(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setFormattedSignupBonusAmount: (amount: string) =>
    dispatch(setFormattedSignupBonusAmountAction(amount)),
});

type PropsType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const App = ({
  locale,
  signupBonusID,
  setFormattedSignupBonusAmount,
}: PropsType) => {
  useEffect(() => {
    const fetchBonusAmount = async () => {
      if (signupBonusID) {
        try {
          const { formatted_bonus_amount: formattedBonusAmount } =
            await GetFormattedSignupBonusService(signupBonusID);
          if (formattedBonusAmount) {
            setFormattedSignupBonusAmount(formattedBonusAmount);
          }
        } catch (error) {
          // error
        }
      }
    };
    fetchBonusAmount();
  }, [setFormattedSignupBonusAmount, signupBonusID]);

  return (
    <LegoProvider locale={locale}>
      <ErrorBoundary>
        <Suspense fallback={<WishDotLoaderModal />}>
          <Switch>
            <Route exact path={LANDING_PAGE_ROUTE} component={LandingPage} />
            <Route
              exact
              path={SIGNUP_PAGE_ROUTE}
              component={MultiScreenSignupPage}
            />
            <Route
              exact
              path={SINGLE_SIGNUP_PAGE_ROUTE}
              component={SinglePageSignup}
            />
            <Route exact path={COMMON_HELP_ROUTES} component={HelpPage} />
            {/* Catch all and redirect, needs to be the last */}
            <Route path="*" component={NoMatchRedirect} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </LegoProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
