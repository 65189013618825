import { CSSProperties } from 'react';

/* Styled Components */
import { Wrapper, Dot } from 'styles/common/DotLoader';

type PropsType = {
  overrideStyle?: CSSProperties;
};

export default ({ overrideStyle = {} }: PropsType) => (
  <Wrapper
    style={{ ...overrideStyle }}
    // progressbar role MDN reference: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role
    role="progressbar"
  >
    <Dot color={overrideStyle?.color} />
    <Dot color={overrideStyle?.color} delay={1} />
    <Dot color={overrideStyle?.color} delay={2} />
  </Wrapper>
);
