/* Utils */
import call from 'utils/Api';

type GetFormattedSignupBonusServiceType = {
  formatted_bonus_amount?: string;
};

const GetFormattedSignupBonusService = async (
  SignBonusID: string,
): Promise<GetFormattedSignupBonusServiceType> => {
  const { data } = await call('init_data/formatted_signup_bonus/get', {
    signup_bonus_id: SignBonusID,
  });
  return data;
};

export default GetFormattedSignupBonusService;
