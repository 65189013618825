import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import type { Metric } from 'web-vitals';

import store from 'app/store';
import theme from 'styles/theme';

/* Services */
import ReportWebVitalsService from 'services/ReportWebVitalsService';

/* Utils */
import { i18n } from 'utils/i18n';
import {
  loadExperimentData,
  setInitData,
  setBaseCookieInfo,
  setVisitorUUID,
  setMarketingAttributesIfAvailable,
  setGoogleClickIDIfAvailable,
} from 'utils/AppInit';
import initSentry from 'utils/Sentry';

import './normalize.css';
import App from './App';
import GlobalStyles from './GlobalStyles';
import reportWebVitals from './reportWebVitals';

const disableDevTools = () => {
  if (process.env.NODE_ENV !== 'development') {
    // @ts-ignore
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
      for (const [key, value] of Object.entries(
        // @ts-ignore
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__,
      )) {
        // @ts-ignore
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          typeof value === 'function' ? () => {} : null;
      }
    }
  }
};

const startApp = async () => {
  setVisitorUUID();
  await Promise.all([
    setInitData(),
    loadExperimentData(),
    setBaseCookieInfo(),
    setMarketingAttributesIfAvailable(),
    setGoogleClickIDIfAvailable(),
  ]);

  ReactDOM.render(
    <React.StrictMode>
      <Helmet>
        <title>{i18n('Wish Local | Get More Shoppers in Your Door')}</title>
        <meta
          name="description"
          content={i18n(
            'Earn additional foot traffic & revenue from Wish. Sign up for free today!',
          )}
        />
      </Helmet>
      <GlobalStyles />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

disableDevTools();
startApp();
initSentry();

// Learn more: https://bit.ly/CRA-vitals
reportWebVitals(async (metric: Metric) => {
  await ReportWebVitalsService(metric);
});
