const theme = {
  black: '#000',
  white: '#fff',
  red: '#F55F48',

  Grey1: '#192a32',
  Grey2: '#48636f',
  Grey3: '#7e9aa6',
  Grey4: '#afc7d1',
  Grey5: '#d4e3eb',
  Grey6: '#f0f5f7',
  Grey7: '#f8fafb',
  Grey8: '#9EADBB',
  Grey9: '#D5DFE6',

  Red1: '#A50821',
  Red2: '#C61B36',
  Red3: '#DB2441',
  Red4: '#F95670',
  Red5: '#FC8FA1',
  Red6: '#C22762',
  Red7: '#E14D4C',

  Blue1: '#001D53',
  Blue2: '#1A4379',
  Blue3: '#0098d3',
  Blue4: '#F0F5F7',

  palettesGreen100: '#f4ffe6',
  palettesGreen600: '#5eab00',
  palettesPurple600: '#551fd0',
  palettesPurple900: '#19004D',
  palettesYellow600: '#BE8B00',
  palettesBlue100: '#E6F8FF',
  palettesBlue200: '#C8EEFD',
  palettesBlue600: '#0073bf',
  palettesGrey100: '#F7F9FA',
  palettesGrey200: '#eff4f6',
  palettesGrey400: '#BFCDD4',
  palettesGrey800: '#1C2C36',

  pacifica: '#0d6fff',
  darkCobalt: '#305bef',
  lightCobalt: '#f0f4ff',
  pickupGreen: '#2eaa77',
  primaryColor: '#2fb7ec',
  borderBrandGuidelinesGrey: '#D5DFE6',
  PickupGreen: '#2eaa77',
  wishOrange: '#ef8d2e',
  wishRed: '#ec2f4d',
  wishLightYellow: '#ffd560',
  wishDarkYellow: '#f0b616',
  SuccessGreen: '#1acc94',

  zIndexBase1: 1,
  zIndexBase2: 2,
  zIndexBase3: 3,
  zIndexBase4: 4,
  zIndexOverlay1: 5,
  zIndexOverlay2: 6,
  zIndexOverlay3: 7,
  zIndexOverlay4: 8,
  zIndexModal1: 9,
  zIndexModal2: 10,
  zIndexModal3: 11,
  zIndexModal4: 12,

  isDark: false,

  totalHeaderHeight: '104px',
  mobileHeaderHeight: 56,
};

export default theme;
