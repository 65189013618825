import styled from 'styled-components';

/* Mixins */
import { FlexCenterChildren, BoxSizingSelfAndChildren } from 'styles/Mixins';
import { opaque } from 'styles/Utils';

/* Types */
import type { CSSProperties } from 'react';

const DEFAULT_MODAL_PADDING = '40px';
const DEFAULT_MODAL_WIDTH = '300px';

export const PlainBackDrop = styled.div<{ [props: string]: any }>`
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) =>
    opaque(theme.isDark ? theme.Grey6 : theme.Grey1, 0.7)};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: all 0.4s ease;
`;

export const BackDrop = styled(PlainBackDrop)`
  display: flex;
  z-index: ${({ theme }) => theme.zIndexModal2};
  ${BoxSizingSelfAndChildren};
  justify-content: center;

  /* IE 11 Selector */
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    ${FlexCenterChildren};
  }
`;

export const BasicModal = styled.div.attrs(() => ({
  // don't click backdrop or modal will be closed
  onClick: event => event.stopPropagation(),
}))<{ style: CSSProperties; [props: string]: any }>`
  background-color: ${({ theme }) =>
    theme.isDark ? theme.white : theme.white};
  min-width: ${({ style }) =>
    style.width ? style.width : DEFAULT_MODAL_WIDTH};
  text-align: center;
  padding: ${({ style }) =>
    style.padding ? style.padding : DEFAULT_MODAL_PADDING};
  color: ${({ theme }) => theme.Grey1};
  position: relative;
  margin: ${({ style }) => style.margin || 'auto'};
  border-radius: ${({ style }) => style.borderRadius || '4px'};
  ${({ style }) => (style.top ? `top: ${style.top}` : '')};

  /* IE 11 Selector */
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin: auto 0;
    ${({ internetExplorerAbsolute }) =>
      internetExplorerAbsolute
        ? `
          position: absolute;
          top: 0;
          transform: translateX(-50%);
        `
        : ''};
  }
`;

const DEFAULT_POSITION = '34px';
export const CloseButton = styled.div<{ [props: string]: any }>`
  position: absolute;
  top: ${({ position, topPosition }) =>
    topPosition || position || DEFAULT_POSITION};
  ${({ placeLeft, position }) =>
    `${placeLeft ? 'left' : 'right'}: ${position || DEFAULT_POSITION}`};
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndexModal3};
  max-height: 0px;
`;
