/* Utils */
import call from 'utils/Api';

type GetInitDataServiceType = {
  locale?: string;
  country?: string;
  environment?: string;
  xsrf_token?: string;
};

const GetInitDataService = async (): Promise<GetInitDataServiceType> => {
  const { data } = await call('init_data/get');
  return data;
};

export default GetInitDataService;
