/* Utils */
import call from 'utils/Api';

type LogJSErrorServiceType = {
  name: string;
  message: string;
  componentStack: string;
};

const LogJSErrorService = async ({
  name,
  message,
  componentStack,
}: LogJSErrorServiceType) => {
  try {
    await call('analytics/error_log', {
      name,
      message,
      component_stack: componentStack,
    });
  } catch (e) {
    // error
  }
};

export default LogJSErrorService;
