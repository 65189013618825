import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

/* Constants */
import { MONOLITH_ENV } from 'constants/AppConstants';
import { SIGNUP_SCREEN, STORE_TYPE } from 'constants/SignupConstants';
import { BLUE_VISITOR_UUID } from 'constants/AppConstants';

/* Selectors */
import { getEnv } from 'app/appSlice';

/* Services */
import SubmitStoreSignupService from 'services/SubmitSignupStepService';

/* Types */
import type { AppState } from 'app/store';
import type { OptionsType as SubmitSignupStepOptionsType } from 'services/SubmitSignupStepService';
import type { PlaceDetailsResultType } from 'types/GooglePlacesTypes';
import type { StoreHours } from 'types/MultiScreenSignupTypes';

/* Utils */
import { getSessionStorage } from 'utils/SessionStorage';
import { HOURS_SET_0, HOURS_SET_1, WEEKDAYS } from 'utils/StoreHours';
import {
  sendGAEvent,
  GA_GROUP_NAME,
  EVENT_ACTION,
  EVENT_CATEGORY,
} from 'utils/GoogleAnalytics';

type StateType = {
  currentSignupStep: Values<typeof SIGNUP_SCREEN>;
  errorMessage?: string | null;
  isFormSubmitting: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  storeCategory: number | null;
  storeAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    zipCode: string;
    state: string;
    country: string;
    phoneNumber: string;
    countryCode: string;
  };
  storeName: string;
  autocompleteSessionToken: string;
  autocompleteAttributions: Array<string>;
  placeDetailsResult?: PlaceDetailsResultType;
  justDidAutoComplete: boolean;
  isStoreNameValidated: boolean;
  dayToIsOpenMap: Array<boolean>;
  storeHours: StoreHours;
  isSecondHoursShiftVisible: boolean;
  referralCode?: string;
  storeType: Values<typeof STORE_TYPE> | null | undefined;
};
const initialState: StateType = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  storeCategory: null,
  storeAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    zipCode: '',
    state: '',
    country: '',
    phoneNumber: '',
    countryCode: '',
  },
  storeName: '',
  autocompleteSessionToken: '',
  autocompleteAttributions: [],
  justDidAutoComplete: false,
  isStoreNameValidated: false,
  dayToIsOpenMap: [false, false, false, false, false, false, false],
  isSecondHoursShiftVisible: false,
  referralCode: '',
  errorMessage: null,
  isFormSubmitting: false,
  storeHours: {
    [WEEKDAYS.MONDAY]: {
      [HOURS_SET_0]: { start: '', end: '' },
      [HOURS_SET_1]: { start: '', end: '' },
    },
    [WEEKDAYS.TUESDAY]: {
      [HOURS_SET_0]: { start: '', end: '' },
      [HOURS_SET_1]: { start: '', end: '' },
    },
    [WEEKDAYS.WEDNESDAY]: {
      [HOURS_SET_0]: { start: '', end: '' },
      [HOURS_SET_1]: { start: '', end: '' },
    },
    [WEEKDAYS.THURSDAY]: {
      [HOURS_SET_0]: { start: '', end: '' },
      [HOURS_SET_1]: { start: '', end: '' },
    },
    [WEEKDAYS.FRIDAY]: {
      [HOURS_SET_0]: { start: '', end: '' },
      [HOURS_SET_1]: { start: '', end: '' },
    },
    [WEEKDAYS.SATURDAY]: {
      [HOURS_SET_0]: { start: '', end: '' },
      [HOURS_SET_1]: { start: '', end: '' },
    },
    [WEEKDAYS.SUNDAY]: {
      [HOURS_SET_0]: { start: '', end: '' },
      [HOURS_SET_1]: { start: '', end: '' },
    },
  },
  currentSignupStep: SIGNUP_SCREEN.CREATE_ACCOUNT,
  storeType: null,
};

// Thunk
export const submitSignupStep = createAsyncThunk(
  'signup/submitStep',
  async (
    {
      signupStep,
      nextSignupStep,
      options: {
        firstName,
        lastName,
        email,
        password,
        storeType,
        csStoreType,
        storeAddressLine1,
        storeAddressLine2,
        city,
        zipCode,
        state,
        country,
        countryCode,
        phoneNumber,
        storeName,
        placeDetailsResult,
        autocompleteAttributions,
        storeNameValidated,
        hoursList,
        storeCategory,
        referralCode,
      },
    }: {
      signupStep: Values<typeof SIGNUP_SCREEN>;
      nextSignupStep: Values<typeof SIGNUP_SCREEN>;
      options: SubmitSignupStepOptionsType;
    },
    { rejectWithValue, getState },
  ) => {
    const appState = getState() as AppState;
    const env = getEnv(appState);
    const callMonolith = MONOLITH_ENV.includes(env);

    try {
      const visitorUUID = getSessionStorage(BLUE_VISITOR_UUID) || '';
      const data = await SubmitStoreSignupService(
        visitorUUID,
        signupStep,
        callMonolith,
        {
          firstName,
          lastName,
          email,
          password,
          storeType,
          csStoreType,
          storeAddressLine1,
          storeAddressLine2,
          city,
          zipCode,
          state,
          country,
          countryCode,
          phoneNumber,
          storeName,
          placeDetailsResult,
          autocompleteAttributions,
          storeNameValidated,
          hoursList,
          storeCategory,
          referralCode,
        },
      );
      sendGAEvent(GA_GROUP_NAME, EVENT_ACTION.BUTTON_CLICK, {
        event_category: EVENT_CATEGORY.SIGNUP_PAGE,
        event_label: `${signupStep.toLowerCase()}_submit`,
      });
      return { data, nextSignupStep };
    } catch (err: any) {
      if (!err?.response?.data) {
        throw err;
      }
      const { message, msg } = err.response.data;
      return rejectWithValue(message || msg);
    }
  },
);

// Slice
export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    clearErrorMessage: state => {
      state.errorMessage = initialState.errorMessage;
    },
    setStoreType: (state, action: PayloadAction<Values<typeof STORE_TYPE>>) => {
      state.storeType = action.payload;
    },
    setStoreCategory: (state, action: PayloadAction<number>) => {
      state.storeCategory = action.payload;
    },
    setCurrentSignupStep: (
      state,
      action: PayloadAction<Values<typeof SIGNUP_SCREEN>>,
    ) => {
      state.currentSignupStep = action.payload;
    },
    setStoreName: (state, action: PayloadAction<string>) => {
      state.storeName = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.storeAddress.country = action.payload;
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.storeAddress.countryCode = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.storeAddress.state = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.storeAddress.city = action.payload;
    },
    setStoreAddressLine1: (state, action: PayloadAction<string>) => {
      state.storeAddress.addressLine1 = action.payload;
    },
    setStoreAddressLine2: (state, action: PayloadAction<string>) => {
      state.storeAddress.addressLine2 = action.payload;
    },
    setZipCode: (state, action: PayloadAction<string>) => {
      state.storeAddress.zipCode = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.storeAddress.phoneNumber = action.payload;
    },
    setAutocompleteSessionToken: (state, action: PayloadAction<string>) => {
      state.autocompleteSessionToken = action.payload;
    },
    setAutocompleteAttributions: (
      state,
      action: PayloadAction<Array<string>>,
    ) => {
      state.autocompleteAttributions = action.payload;
    },
    setIsStoreNameValidated: (state, action: PayloadAction<boolean>) => {
      state.isStoreNameValidated = action.payload;
    },
    setIsSecondHoursShiftVisible: (state, action: PayloadAction<boolean>) => {
      state.isSecondHoursShiftVisible = action.payload;
    },
    setJustDidAutoComplete: (state, action: PayloadAction<boolean>) => {
      state.justDidAutoComplete = action.payload;
    },
    setStoreHours: (state, action: PayloadAction<StoreHours>) => {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const dayIndex in action.payload) {
        state.storeHours[dayIndex] = action.payload[dayIndex];
      }
    },
    setStoreStartHour: (
      state,
      action: PayloadAction<{
        dayIndex: number;
        setOfHoursIndex: number;
        startHour: string;
      }>,
    ) => {
      const { dayIndex, setOfHoursIndex, startHour } = action.payload;
      if (!state.storeHours[dayIndex][setOfHoursIndex]) {
        state.storeHours[dayIndex][setOfHoursIndex] = { start: '', end: '' };
      }
      state.storeHours[dayIndex][setOfHoursIndex].start = startHour;
    },
    setStoreEndHour: (
      state,
      action: PayloadAction<{
        dayIndex: number;
        setOfHoursIndex: number;
        endHour: string;
      }>,
    ) => {
      const { dayIndex, setOfHoursIndex, endHour } = action.payload;
      if (!state.storeHours[dayIndex][setOfHoursIndex]) {
        state.storeHours[dayIndex][setOfHoursIndex] = { start: '', end: '' };
      }
      state.storeHours[dayIndex][setOfHoursIndex].end = endHour;
    },
    setPlaceDetailsResult: (
      state,
      action: PayloadAction<PlaceDetailsResultType | undefined>,
    ) => {
      state.placeDetailsResult = action.payload;
    },
    setIsOpenOnDay: (
      state,
      action: PayloadAction<{ dayIndex: number; isOpenOnDay: boolean }>,
    ) => {
      const { dayIndex, isOpenOnDay } = action.payload;
      state.dayToIsOpenMap[dayIndex] = isOpenOnDay;
    },
    setReferralCode: (state, action: PayloadAction<string>) => {
      state.referralCode = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(submitSignupStep.pending, state => {
        state.isFormSubmitting = true;
      })
      .addCase(submitSignupStep.fulfilled, (state, action) => {
        state.isFormSubmitting = false;
        state.currentSignupStep = action.payload.nextSignupStep;
      })
      .addCase(submitSignupStep.rejected, (state, action) => {
        state.isFormSubmitting = false;
        state.errorMessage = action.payload as string | undefined;
      });
  },
});

// Actions
export const {
  setFirstName,
  setLastName,
  setEmail,
  setPassword,
  setErrorMessage,
  clearErrorMessage,
  setStoreType,
  setStoreCategory,
  setCurrentSignupStep,
  setStoreName,
  setCountry,
  setCountryCode,
  setState,
  setCity,
  setZipCode,
  setPhoneNumber,
  setStoreAddressLine1,
  setStoreAddressLine2,
  setStoreHours,
  setStoreStartHour,
  setStoreEndHour,
  setPlaceDetailsResult,
  setAutocompleteAttributions,
  setAutocompleteSessionToken,
  setIsStoreNameValidated,
  setIsSecondHoursShiftVisible,
  setJustDidAutoComplete,
  setIsOpenOnDay,
  setReferralCode,
} = signupSlice.actions;

// Selectors
export const getFirstName = (state: AppState) => state.signup.firstName;
export const getLastName = (state: AppState) => state.signup.lastName;
export const getEmail = (state: AppState) => state.signup.email;
export const getPassword = (state: AppState) => state.signup.password;
export const getStoreAddressLine1 = (state: AppState) =>
  state.signup.storeAddress.addressLine1;
export const getStoreAddressLine2 = (state: AppState) =>
  state.signup.storeAddress.addressLine2;
export const getCity = (state: AppState) => state.signup.storeAddress.city;
export const getZipCode = (state: AppState) =>
  state.signup.storeAddress.zipCode;
export const getState = (state: AppState) => state.signup.storeAddress.state;
export const getCountry = (state: AppState) =>
  state.signup.storeAddress.country;
export const getCountryCode = (state: AppState) =>
  state.signup.storeAddress.countryCode;
export const getPhoneNumber = (state: AppState) =>
  state.signup.storeAddress.phoneNumber;
export const getErrorMessage = (state: AppState) => state.signup.errorMessage;
export const getIsFormSubmitting = (state: AppState) =>
  state.signup.isFormSubmitting;
export const getCurrentSignupStep = (state: AppState) =>
  state.signup.currentSignupStep;
export const getStoreType = (state: AppState) => state.signup.storeType;
export const getStoreCategory = (state: AppState) => state.signup.storeCategory;
export const getStoreName = (state: AppState) => state.signup.storeName;
export const getAutocompleteSessionToken = (state: AppState) =>
  state.signup.autocompleteSessionToken;
export const getAutocompleteAttributions = (state: AppState) =>
  state.signup.autocompleteAttributions;
export const getPlaceDetailsResult = (state: AppState) =>
  state.signup.placeDetailsResult;
export const getGooglePlacesOpeningHours = (state: AppState) =>
  state.signup.placeDetailsResult?.opening_hours;
export const getJustDidAutoComplete = (state: AppState) =>
  state.signup.justDidAutoComplete;
export const isStoreNameValidated = (state: AppState) =>
  state.signup.isStoreNameValidated;
export const getDayToIsOpenMap = (state: AppState) =>
  state.signup.dayToIsOpenMap;
export const getStoreHours = (state: AppState) => state.signup.storeHours;
export const isSecondHoursShiftVisible = (state: AppState) =>
  state.signup.isSecondHoursShiftVisible;
export const getReferralCode = (state: AppState) => state.signup.referralCode;

// Reducer
const signupReducer = signupSlice.reducer;
export default signupReducer;
