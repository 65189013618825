import React from 'react';
import type { ComponentType } from 'react';
import { debounce } from 'lodash';

/* Styled Components */
import { BackDrop, BasicModal, CloseButton } from 'styles/common/BaseModal';

/* Child Components */
import WishClose from 'components/common/WishClose';

const CLOSE_TRANSITION_LENGTH = 500; // ms

export type InjectedPropsType = {
  isClosed: boolean | void;
  closeModal: () => Promise<void>;
};
export type HocOnlyPropsTypes = {
  onClose?: () => void;
  onClickBackDrop?: () => void;
  onClickCloseButton?: () => void;
};

export type StateType = {
  isVisible: boolean;
  isClosed: boolean;
  internetExplorerAbsolute: boolean;
};
const BaseModalHOC = <P extends InjectedPropsType = InjectedPropsType>(
  WrappedComponent: ComponentType<P>,
  {
    style = {},
    backDropStyle = {},
    backdropID,
    showCloseButton = true,
    wishCloseColor,
    closeOnClickBackDrop = true,
    closeButtonOnBackdrop = false,
  }: {
    style?: {
      padding?: string;
      margin?: string;
      closeButtonPosition?: string;
      closeButtonTopPosition?: string;
      minWidth?: string;
      closeButtonPlaceLeft?: boolean;
      width?: string;
      height?: string;
      background?: string;
      borderRadius?: string;
      backgroundColor?: string;
      textAlign?: string;
      maxHeight?: string;
      overflow?: string;
      display?: string;
      alignSelf?: string;
      top?: string;
    };
    backDropStyle?: { top?: string; background?: string };
    backdropID?: string;
    showCloseButton?: boolean;
    wishCloseColor?: string;
    closeOnClickBackDrop?: boolean;
    closeButtonOnBackdrop?: boolean;
  } = {},
): ComponentType<Omit<P, keyof InjectedPropsType> & HocOnlyPropsTypes> => {
  return class extends React.Component<
    Omit<P, keyof InjectedPropsType> & HocOnlyPropsTypes,
    StateType
  > {
    element: HTMLElement | null | undefined;

    closeTimerID!: number;

    constructor(props: Omit<P, keyof InjectedPropsType> & HocOnlyPropsTypes) {
      super(props);
      this.state = {
        isVisible: false,
        isClosed: false,
        internetExplorerAbsolute: false,
      };
    }

    componentDidMount() {
      setTimeout(() => this.setState({ isVisible: true }), 0);
      window.addEventListener('resize', this.onResize, true);
    }

    componentWillUnmount() {
      window.clearTimeout(this.closeTimerID);
      window.removeEventListener('resize', this.onResize, true);
    }

    onResize = debounce(() => {
      if (this.element === null || this.element === undefined) {
        return;
      }

      const internetExplorerAbsolute =
        window.innerHeight < this.element.clientHeight;
      if (internetExplorerAbsolute !== this.state.internetExplorerAbsolute) {
        this.setState({ internetExplorerAbsolute });
      }
    }, 10);

    onClose = (): Promise<void> =>
      new Promise(resolve => {
        if (this.state.isVisible) {
          this.setState({ isVisible: false }, () => {
            this.closeTimerID = window.setTimeout(() => {
              if (typeof this.props.onClose === 'function') {
                this.props.onClose();
              } else {
                this.setState({ isClosed: true });
              }
              resolve();
            }, CLOSE_TRANSITION_LENGTH);
          });
        }
      });

    onClickBackDrop = () => {
      if (closeOnClickBackDrop) {
        this.onClose();
      }

      const { onClickBackDrop } = this.props;
      if (onClickBackDrop && typeof onClickBackDrop === 'function') {
        onClickBackDrop();
      }
    };

    onClickCloseButton = () => {
      const { onClickCloseButton } = this.props;
      if (onClickCloseButton && typeof onClickCloseButton === 'function') {
        onClickCloseButton();
      } else {
        this.onClose();
      }
    };

    setRef = (element: HTMLElement | null | undefined) => {
      this.element = element;
    };

    render() {
      const { onClose: _, ...propsToPass } = this.props;

      return (
        <BackDrop
          isVisible={this.state.isVisible}
          onClick={this.onClickBackDrop}
          onScroll={this.onResize}
          style={backDropStyle}
          id={backdropID}
        >
          {closeButtonOnBackdrop && (
            <CloseButton onClick={this.onClickCloseButton}>
              <WishClose width="21px" height="21px" color="#fff" />
            </CloseButton>
          )}
          <BasicModal
            style={style}
            internetExplorerAbsolute={this.state.internetExplorerAbsolute}
            ref={this.setRef}
          >
            <WrappedComponent
              {...(propsToPass as unknown as P)}
              closeModal={this.onClose}
              isClosed={this.state.isClosed}
            />
            {showCloseButton && (
              <CloseButton
                onClick={this.onClickCloseButton}
                position={style.closeButtonPosition}
                topPosition={style.closeButtonTopPosition}
                placeLeft={style.closeButtonPlaceLeft}
              >
                <WishClose width="14px" height="14px" color={wishCloseColor} />
              </CloseButton>
            )}
          </BasicModal>
        </BackDrop>
      );
    }
  };
};

export default BaseModalHOC;
