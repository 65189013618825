import { css, keyframes } from 'styled-components';

export const TextOverflowEllipsis = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HorizontalMarginAuto = `
  margin-left: auto;
  margin-right: auto;
`;

export const InvisibleHorizontalScrollBar = `
  overflow-x:scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
  }
  scrollbar-width:none;
  overflow-style: none;
`;

export const InvisibleVerticalScrollBar = `
  overflow-y:scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  ::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
    background: transparent;
  }
`;

export const VerticalRelativeCenter = `
  position: relative;
  vertical-align: top;
  top: 50%;
  transform: translateY(-50%);
`;

const flexCenterChildren = `
  align-items: center;
  justify-content: center;
`;

export const FlexSpaceBetween = `
  display: flex;
  justify-content: space-between;
`;

export const FlexStart = `
  display: flex;
  justify-content: flex-start;
`;

export const FlexCenterChildren = `
  display: flex;
  ${flexCenterChildren}
`;

export const FlexDirectionColumn = `
  display: flex;
  flex-direction: column;
`;

export const FlexRow = `
  display: flex;
  flex-direction: row;
`;

export const FlexCenterChildrenColumn = `
  ${FlexDirectionColumn}
  ${flexCenterChildren}
`;

export const InlineFlexCenterChildren = `
  display: inline-flex;
  ${flexCenterChildren}
`;

export const MobileSmoothScroll = `
  -webkit-overflow-scrolling: touch;
`;

export const CenterBackgroundImage = `
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const WebFontHeader1 = `
  font-size: 56px;
  font-weight: 600;
`;
export const WebFontHeader2Bold = `
  font-size: 40px;
  font-weight: 700;
`;
export const WebFontHeader2 = `
  font-size: 40px;
  font-weight: 600;
`;
export const WebFontHeader3 = `
  font-size: 32px;
  font-weight: 700;
`;
export const WebFontHeader4 = `
  font-size: 24px;
  font-weight: 700;
`;
export const WebFontHeader5 = `
  font-size: 20px;
  font-weight: 700;
`;
export const WebFontHeader6 = `
  font-size: 18px;
  font-weight: 700;
`;
export const WebFontHeaderSemibold6 = `
  font-size: 18px;
  font-weight: 600;
`;
export const WebFontCallout1 = `
  font-size: 16px;
  font-weight: 700;
`;
export const WebFontCalloutSemibold1 = `
  font-size: 16px;
  font-weight: 600;
`;
export const WebFontCallout2 = `
  font-size: 14px;
  font-weight: 700;
`;
export const WebFontCalloutSemibold2 = `
  font-size: 14px;
  font-weight: 600;
`;
export const WebFontCallout3 = `
  font-size: 12px;
  font-weight: 700;
`;
export const WebFontTitle1 = `
  font-size: 24px;
  font-weight: 400;
`;
export const WebFontTitle2 = `
  font-size: 20px;
  font-weight: 400;
`;
export const WebFontTitle3 = `
  font-size: 18px;
  font-weight: 400;
`;
export const WebFontBody1 = `
  font-size: 16px;
  font-weight: 400;
`;
export const WebFontBody2 = `
  font-size: 14px;
  font-weight: 400;
`;
export const WebFontCaption1 = `
  font-size: 12px;
  font-weight: 400;
`;
export const WebFontCaption2 = `
  font-size: 10px;
  font-weight: 400;
`;

export const withLineHeight = `
  line-height: 1.5;
`;

export const BoxSizingSelfAndChildren = `
  box-sizing: border-box;

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }
`;

const fadeInKeyframe = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const fadeInAnimationRule = (duration: number) => css`
  ${fadeInKeyframe} ${duration}s both;
`;

// duration in seconds
export const FadeIn = (duration: number) => css`
  animation: ${fadeInAnimationRule(duration)};
`;

// Padding to account for height if needing to link to same spot on page
// Ex.
// ${({ theme }) => HeightBeforeMixin(theme.totalHeaderHeight)}
export const HeightBeforeMixin = (height: string) => `
    :before {
    content: ' ';
    display: block;
    height: ${height};
    margin-top: -${height};
    visibility: hidden;
  }`;
