import { configureStore } from '@reduxjs/toolkit';

/* Reducers */
import appReducer from 'app/appSlice';
import signupReducer from 'app/signupSlice';
import marketingReducer from 'app/marketingSlice';
import helpPageReducer from 'app/helpPageSlice';
import experimentReducer from 'app/experimentSlice';

const store = configureStore({
  reducer: {
    app: appReducer,
    signup: signupReducer,
    marketing: marketingReducer,
    helpPage: helpPageReducer,
    experiment: experimentReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
