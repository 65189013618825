import React from 'react';
import { Alert } from '@ContextLogic/lego';
import * as Sentry from '@sentry/browser';

/* Services */
import LogJSErrorService from 'services/LogJSErrorService';

/* Utils */
import { i18n } from 'utils/i18n';

type PropsType = {
  children?: React.ReactNode;
};

type StateType = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { name, message } = error;
    const { componentStack } = errorInfo;
    LogJSErrorService({ name, message, componentStack });
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert
          sentiment="negative"
          title={i18n('Something went wrong')}
          text={i18n('Sorry, something went wrong.')}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
