export const getSessionStorage = (name: string) => {
  if (!process.env.SSR) {
    return window.sessionStorage.getItem(name);
  }
  return null;
};

export const setSessionStorage = (name: string, value: string) => {
  if (!process.env.SSR) {
    window.sessionStorage.setItem(name, value);
  }
};

export const setSessionStorageIfNotExist = (name: string, value: string) => {
  if (!process.env.SSR && !getSessionStorage(name)) {
    window.sessionStorage.setItem(name, value);
  }
};
