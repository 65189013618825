import type { ReactNode } from 'react';
import React, { isValidElement } from 'react';
import * as Sentry from '@sentry/browser';

let localeMap: any;

const regex = /%(\d+)\$[ds]|\{%(\d+)=[^{}]+\}/g;
const CAPTURE_GROUP_INDEX = 1;

const wrapByReactFragment = (component: ReactNode, key: number) => (
  <React.Fragment key={key}>{component}</React.Fragment>
);

/**
 * Will attempt to concat `newItem` with the last element of `array` if both are
 * not an object. If either are an object, then push `newItem` to the end of
 * `array`.
 * @param {*} array Array to merge or insert into
 * @param {*} newItem The new element to attempt to merge or push into the array
 * @param {*} key Used for when newItem is object and being wrapped in a react
 *                fragment
 */
const mergeOrPush = (array: Array<any>, newItem: any, key: number) => {
  if (array.length === 0) {
    array.push(newItem);
    return;
  }

  const lastElement = array[array.length - 1];
  if (typeof lastElement === 'object' || typeof newItem === 'object') {
    array.push(wrapByReactFragment(newItem, key));
  } else {
    array[array.length - 1] = `${lastElement}${newItem}`;
  }
};

/**
 * Function that takes a str and a list of values and inserts
 * the components at the corresponding %_$s/{%_=_..._}
 * @param {*} str String to format
 * @param {*} args Arguments to swap in place of %_$s/{%_=_..._}
 * @example
 * printf('Use coupon code: %1$s and get %2$s% off', [<Component/>, 5]) =>
 *   ['Use coupon code: ', <Component />, ' and get 5% off']
 * printf('Use coupon code: {%1=description of placeholder} and get %2$s% off',
 *   [<Component/>, 5]) => ['Use coupon code: ', <Component />, ' and get 5% off']
 */
export const printf = (str: string, args: Array<any>): Array<ReactNode> => {
  const output = [] as Array<ReactNode>;

  let start = 0;
  let result;
  // eslint-disable-next-line no-cond-assign
  while ((result = regex.exec(str)) !== null) {
    if (result === null || result === undefined) {
      break;
    }

    const beforeMatch = str.substring(start, result.index);
    mergeOrPush(output, beforeMatch, start); // Add before regex match

    // @ts-ignore I dare not change this file
    const captureGroup: number =
      result[CAPTURE_GROUP_INDEX] !== undefined
        ? result[CAPTURE_GROUP_INDEX]
        : result[CAPTURE_GROUP_INDEX + 1];
    const arg = args[captureGroup - 1];
    mergeOrPush(output, arg, result.index); // Swap %_$s with argument
    start = regex.lastIndex;
  }

  // Append the left over
  const leftOver = str.substring(start);
  if (leftOver !== '') {
    mergeOrPush(output, leftOver, start);
  }

  return output;
};

export const sprintf = (str: string, args: Array<any>): string => {
  let output = '';
  let start = 0;
  let result;
  // eslint-disable-next-line no-cond-assign
  while ((result = regex.exec(str)) !== null) {
    if (result === null || result === undefined) {
      break;
    }

    const beforeMatch = str.substring(start, result.index);
    output = `${output}${beforeMatch}`;

    // @ts-ignore I dare not change this file
    const captureGroup: number =
      result[CAPTURE_GROUP_INDEX] !== undefined
        ? result[CAPTURE_GROUP_INDEX]
        : result[CAPTURE_GROUP_INDEX + 1];

    const arg = args[captureGroup - 1];
    output = `${output}${arg}`;
    start = regex.lastIndex;
  }

  // Append the left over
  const leftOver = str.substring(start);
  output = `${output}${leftOver}`;
  return output;
};

const _i18n = (str: string, ...args: ReactNode[]) => {
  try {
    const localeString =
      localeMap && localeMap[str] ? localeMap[str][1] || str : str;
    return sprintf(localeString, args);
  } catch (error) {
    // As a precaution, return english string if sprintf fails
    return str;
  }
};

const _reacti18n = (str: string, ...args: ReactNode[]) => {
  try {
    const localeString =
      localeMap && localeMap[str] ? localeMap[str][1] || str : str;
    return printf(localeString, args);
  } catch (error) {
    // As a precaution, return english string if printf fails
    return [str];
  }
};

export const i18n = <TArgs extends Exclude<ReactNode, null | undefined>[]>(
  str: string,
  ...args: TArgs
): TArgs extends (string | number)[] ? string : ReactNode[] => {
  const shouldUseReacti18n = args.some(isValidElement);
  if (shouldUseReacti18n) {
    // @ts-expect-error compiler doesn't understand that when shouldUseReacti18n is true,
    // TArgs extends (string|number)[] will always be false
    return _reacti18n(str, ...args);
  }

  const translation = _i18n(str, ...args);
  // @ts-expect-error compiler doesn't understand that when shouldUseReacti18n is false,
  // TArgs extends (string|number)[] will always be true
  return translation;
};

export const ni18n = <TArgs extends Exclude<ReactNode, null | undefined>[]>(
  num: number,
  singular: string,
  plural: string,
  placeholder: ReactNode = null,
  ...args: TArgs
): TArgs extends (string | number)[] ? string : ReactNode[] => {
  // place placeholder as first arg if exist instead of num
  if (placeholder) {
    args.splice(0, 0, placeholder);
  } else {
    args.splice(0, 0, num);
  }

  let translations = localeMap
    ? localeMap[singular] || ['', singular, plural]
    : ['', singular, plural];
  if (Array.isArray(translations[1])) {
    translations = ['', translations[1][0], translations[1][0]];
  }
  const str = num === 1 ? translations[1] : translations[2];

  // handle if there is an ReactNode in args
  const shouldUseReacti18n = args.some(isValidElement);
  if (shouldUseReacti18n) {
    // @ts-expect-error compiler doesn't understand that when shouldUseReacti18n is true,
    // TArgs extends (string|number)[] will always be false
    return _reacti18n(str, args);
  }

  const translation = _i18n(str, ...args);
  // @ts-expect-error compiler doesn't understand that when shouldUseReacti18n is false,
  // TArgs extends (string|number)[] will always be true
  return translation;
};

const localeToSmartlingLocale = {
  de: 'de_DE',
  es: 'es_LA',
  fr: 'fr_FR',
  hu: 'hu_HU',
  it: 'it_IT',
  nl: 'nl_NL',
  pt: 'pt_BR',
} as const;

export const isValidLocale = (locale?: string) =>
  locale !== undefined && locale !== null && locale in localeToSmartlingLocale;

// Used for translation on client-side
export const setLocaleMap = async (locale: string) => {
  if (locale in localeToSmartlingLocale) {
    const smartingLocale =
      localeToSmartlingLocale[locale as keyof typeof localeToSmartlingLocale];
    try {
      localeMap = (
        await import(
          `@ContextLogic/wishlocalwebstrings/${smartingLocale}.raw.json`
        )
      ).default;
    } catch (e) {
      localeMap = undefined;
      Sentry.captureException(e);
    }
  } else {
    localeMap = undefined;
  }
};
