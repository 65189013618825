import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* Types */
import type { AppState } from 'app/store';
import type { HelpPageDataType, StateType } from 'types/HelpPageTypes';

const initialState: StateType = {};

// Slice
export const helpPageSlice = createSlice({
  name: 'helpPage',
  initialState,
  reducers: {
    setRetailerFAQInfo: (state, action: PayloadAction<HelpPageDataType>) => {
      state.retailerFAQ = action.payload;
    },
    setRetailerTermsAndConditionsInfo: (
      state,
      action: PayloadAction<HelpPageDataType>,
    ) => {
      state.retailerTermsAndConditions = action.payload;
    },
    setOperatingGuidelines: (
      state,
      action: PayloadAction<HelpPageDataType>,
    ) => {
      state.operatingGuidelines = action.payload;
    },
    setReturnsGuidelines: (state, action: PayloadAction<HelpPageDataType>) => {
      state.returnsGuidelines = action.payload;
    },
    setPickupGuidelines: (state, action: PayloadAction<HelpPageDataType>) => {
      state.pickupGuidelines = action.payload;
    },
    setReferralTermsOfUse: (state, action: PayloadAction<HelpPageDataType>) => {
      state.referralTermsOfUse = action.payload;
    },
  },
});

// Actions
export const {
  setRetailerFAQInfo,
  setRetailerTermsAndConditionsInfo,
  setOperatingGuidelines,
  setReturnsGuidelines,
  setPickupGuidelines,
  setReferralTermsOfUse,
} = helpPageSlice.actions;

// Selectors
export const getRetailerFAQInfo = (state: AppState) =>
  state.helpPage.retailerFAQ;
export const getRetailerTermsAndConditions = (state: AppState) =>
  state.helpPage.retailerTermsAndConditions;
export const getOperatingGuidelines = (state: AppState) =>
  state.helpPage.operatingGuidelines;
export const getReturnsGuidelines = (state: AppState) =>
  state.helpPage.returnsGuidelines;
export const getPickupGuidelines = (state: AppState) =>
  state.helpPage.pickupGuidelines;
export const getReferralTermsOfUse = (state: AppState) =>
  state.helpPage.referralTermsOfUse;

// Reducer
const helpPageReducer = helpPageSlice.reducer;
export default helpPageReducer;
