/* Styled Components */
import { SvgWrapper } from 'styles/common/SVGUtils';

const DEFAULT_COLOR = '#3C4646';

type PropsType = {
  width?: string;
  height?: string;
  color?: string;
  padding?: string;
  margin?: string;
  cursor?: string;
  onClick?: () => void;
};

const WishClose = ({
  width = '10px',
  height = '10px',
  color = DEFAULT_COLOR,
  padding,
  margin,
  cursor,
  onClick,
}: PropsType) => (
  <SvgWrapper
    padding={padding}
    margin={margin}
    cursor={cursor}
    onClick={onClick}
  >
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={color}
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 1l8 8M9 1L1 9" />
      </g>
    </svg>
  </SvgWrapper>
);

export default WishClose;
