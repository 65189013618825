import styled from 'styled-components';
import { assignIfDefined } from 'styles/Utils';

export const SvgWrapper = styled.div<{ [props: string]: any }>`
  ${assignIfDefined('width')};
  ${assignIfDefined('height')};
  ${assignIfDefined('margin')};
  ${assignIfDefined('padding')};
  ${assignIfDefined('line-height')};
  ${assignIfDefined('display')};
  ${assignIfDefined('cursor')};
  ${assignIfDefined('verticalAlign', 'vertical-align')};
  ${assignIfDefined('flexShrink', 'flex-shrink')};
  ${assignIfDefined('alignItems', 'align-items')};
  ${assignIfDefined('justifyContent', 'justify-content')};
  ${assignIfDefined('left')};
  ${assignIfDefined('right')};
  ${assignIfDefined('top')};
  ${assignIfDefined('bottom')};
  ${assignIfDefined('position')};
  ${assignIfDefined('flex')};
`;
