/* HOC */
import BaseModalHOC from 'components/hoc/BaseModalHOC';

/* Child Components */
import DotLoader from 'components/common/DotLoader';

const WishDotLoaderModal = () => <DotLoader />;

export default BaseModalHOC(WishDotLoaderModal, {
  style: {
    width: '100px',
    padding: '40px 15px',
  },
  showCloseButton: false,
  closeOnClickBackDrop: false,
});
