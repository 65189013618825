/* Utils */
import call from 'utils/Api';

/* Types */
import {
  SIGNUP_SCREEN,
  STORE_TYPE,
  CS_STORE_TYPE,
} from 'constants/SignupConstants';
import type { PlaceDetailsResultType } from 'types/GooglePlacesTypes';

export type OptionsType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  storeType?: Values<typeof STORE_TYPE>;
  csStoreType?: Values<typeof CS_STORE_TYPE>;
  storeAddressLine1?: string;
  storeAddressLine2?: string;
  city?: string;
  zipCode?: string;
  state?: string;
  country?: string;
  countryCode?: string;
  phoneNumber?: string;
  storeName?: string;
  placeDetailsResult?: PlaceDetailsResultType;
  autocompleteAttributions?: Array<string>;
  storeNameValidated?: boolean;
  hoursList?: string[];
  storeCategory?: number;
  referralCode?: string;
};

type ReturnType = {
  signup_uuid: string;
};

export default (async function SubmitStoreSignupService(
  signupUUID: string,
  signupStep: Values<typeof SIGNUP_SCREEN>,
  callMonolith: boolean,
  {
    firstName,
    lastName,
    email,
    password,
    storeType,
    csStoreType,
    storeAddressLine1,
    storeAddressLine2,
    city,
    zipCode,
    state,
    country,
    countryCode,
    phoneNumber,
    storeName,
    placeDetailsResult,
    autocompleteAttributions,
    storeNameValidated,
    hoursList,
    storeCategory,
    referralCode,
  }: OptionsType,
): Promise<ReturnType> {
  const { data } = await call(
    callMonolith ? 'store/signup' : 'signup/multipage',
    {
      visitor_uuid: signupUUID,
      signup_step: signupStep,
      first_name: firstName?.trim(),
      last_name: lastName?.trim(),
      email: email?.trim(),
      password,
      store_type: csStoreType !== undefined ? csStoreType : storeType,
      store_address_line_1: storeAddressLine1?.trim(),
      store_address_line_2: storeAddressLine2?.trim(),
      city: city?.trim(),
      state: state?.trim(),
      country: country?.trim(),
      country_code: countryCode?.trim(),
      zip_code: zipCode?.trim(),
      phone_number: phoneNumber?.trim(),
      store_name: storeName?.trim(),
      autocompleted_place_id: placeDetailsResult?.place_id || null,
      autocomplete_details_result: JSON.stringify(placeDetailsResult),
      autocomplete_attributions: autocompleteAttributions,
      store_name_autocomplete_validated: storeNameValidated,
      store_hours: hoursList,
      store_category: storeCategory,
      page_version: 'microservice',
      referral_code: referralCode?.trim(),
    },
    { callMonolith },
  );
  return data;
});
