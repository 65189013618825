/* Utils */
import { i18n } from '../utils/i18n';

const COUNTRY_CODE_TO_NAME = {
  CA: () => i18n('Canada'),
  SH: () => i18n('Saint Helena'),
  LT: () => i18n('Lithuania'),
  KH: () => i18n('Cambodia'),
  AW: () => i18n('Aruba'),
  LK: () => i18n('Sri Lanka'),
  SZ: () => i18n('Swaziland'),
  AR: () => i18n('Argentina'),
  BO: () => i18n('Bolivia'),
  BH: () => i18n('Bahrain'),
  SA: () => i18n('Saudi Arabia'),
  JP: () => i18n('Japan'),
  SI: () => i18n('Slovenia'),
  GT: () => i18n('Guatemala'),
  BA: () => i18n('Bosnia and Herzegovina'),
  DE: () => i18n('Germany'),
  VG: () => i18n('Virgin Islands, British'),
  ES: () => i18n('Spain'),
  MV: () => i18n('Maldives'),
  JM: () => i18n('Jamaica'),
  OM: () => i18n('Oman'),
  MK: () => i18n('North Macedonia'),
  GA: () => i18n('Gabon'),
  NU: () => i18n('Niue'),
  MC: () => i18n('Monaco'),
  NZ: () => i18n('New Zealand'),
  JE: () => i18n('Jersey'),
  PK: () => i18n('Pakistan'),
  AL: () => i18n('Albania'),
  FJ: () => i18n('Fiji'),
  IN: () => i18n('India'),
  AZ: () => i18n('Azerbaijan'),
  AE: () => i18n('United Arab Emirates'),
  KE: () => i18n('Kenya'),
  KR: () => i18n('South Korea'),
  TR: () => i18n('Turkey'),
  BD: () => i18n('Bangladesh'),
  LC: () => i18n('Saint Lucia'),
  SM: () => i18n('San Marino'),
  MN: () => i18n('Mongolia'),
  FR: () => i18n('France'),
  BM: () => i18n('Bermuda'),
  SK: () => i18n('Slovakia'),
  PE: () => i18n('Peru'),
  NO: () => i18n('Norway'),
  GB: () => i18n('United Kingdom (Great Britain)'),
  SG: () => i18n('Singapore'),
  ME: () => i18n('Montenegro'),
  TW: () => i18n('Taiwan, China'),
  AM: () => i18n('Armenia'),
  DO: () => i18n('Dominican Republic'),
  UA: () => i18n('Ukraine'),
  GH: () => i18n('Ghana'),
  TO: () => i18n('Tonga'),
  KY: () => i18n('Cayman Islands'),
  FI: () => i18n('Finland'),
  MU: () => i18n('Mauritius'),
  SE: () => i18n('Sweden'),
  VN: () => i18n('Vietnam'),
  RU: () => i18n('Russia'),
  BG: () => i18n('Bulgaria'),
  US: () => i18n('United States'),
  RO: () => i18n('Romania'),
  PT: () => i18n('Portugal'),
  ZA: () => i18n('South Africa'),
  NI: () => i18n('Nicaragua'),
  LI: () => i18n('Liechtenstein'),
  MY: () => i18n('Malaysia'),
  AT: () => i18n('Austria'),
  HU: () => i18n('Hungary'),
  BR: () => i18n('Brazil'),
  KW: () => i18n('Kuwait'),
  PA: () => i18n('Panama'),
  CR: () => i18n('Costa Rica'),
  LU: () => i18n('Luxembourg'),
  VI: () => i18n('Virgin Islands, U.S.'),
  BS: () => i18n('Bahamas'),
  GI: () => i18n('Gibraltar'),
  IE: () => i18n('Ireland'),
  NG: () => i18n('Nigeria'),
  EC: () => i18n('Ecuador'),
  CZ: () => i18n('Czech Republic'),
  AU: () => i18n('Australia'),
  DZ: () => i18n('Algeria'),
  SV: () => i18n('El Salvador'),
  CL: () => i18n('Chile'),
  PR: () => i18n('Puerto Rico'),
  BE: () => i18n('Belgium'),
  TH: () => i18n('Thailand'),
  BZ: () => i18n('Belize'),
  GE: () => i18n('Georgia'),
  DK: () => i18n('Denmark'),
  PL: () => i18n('Poland'),
  MD: () => i18n('Moldova'),
  MA: () => i18n('Morocco'),
  NA: () => i18n('Namibia'),
  CH: () => i18n('Switzerland'),
  GD: () => i18n('Grenada'),
  SC: () => i18n('Seychelles'),
  EE: () => i18n('Estonia'),
  UY: () => i18n('Uruguay'),
  UZ: () => i18n('Uzbekistan'),
  EG: () => i18n('Egypt'),
  AG: () => i18n('Antigua and Barbuda'),
  DM: () => i18n('Dominica'),
  CO: () => i18n('Colombia'),
  RE: () => i18n('Reunion'),
  CY: () => i18n('Cyprus'),
  BB: () => i18n('Barbados'),
  QA: () => i18n('Qatar'),
  IT: () => i18n('Italy'),
  BT: () => i18n('Bhutan'),
  NP: () => i18n('Nepal'),
  MT: () => i18n('Malta'),
  NL: () => i18n('Netherlands'),
  SR: () => i18n('Suriname'),
  AI: () => i18n('Anguilla'),
  VE: () => i18n('Venezuela'),
  IL: () => i18n('Israel'),
  ID: () => i18n('Indonesia'),
  IS: () => i18n('Iceland'),
  ZM: () => i18n('Zambia'),
  JO: () => i18n('Jordan'),
  KZ: () => i18n('Kazakhstan'),
  PH: () => i18n('Philippines'),
  KG: () => i18n('Kyrgyzstan'),
  MS: () => i18n('Montserrat'),
  AD: () => i18n('Andorra'),
  TT: () => i18n('Trinidad and Tobago'),
  LV: () => i18n('Latvia'),
  GY: () => i18n('Guyana'),
  HN: () => i18n('Honduras'),
  MM: () => i18n('Myanmar'),
  MX: () => i18n('Mexico'),
  TN: () => i18n('Tunisia'),
  RS: () => i18n('Serbia'),
  GR: () => i18n('Greece'),
  PY: () => i18n('Paraguay'),
  HR: () => i18n('Croatia'),
  BW: () => i18n('Botswana'),
} as const;

const COUNTRY_STATE_MAPPING: {
  [countryCode: string]: Array<string>;
} = {
  CL: [
    'Antofagasta (2)',
    'Arica y Parinacota (15)',
    'Atacama (3)',
    'Aysén del General Carlos Ibáñez del Campo (11)',
    'Biobío (8)',
    'Coquimbo (4)',
    'La Araucanía (9)',
    "Libertador General Bernardo O'Higgins (6)",
    'Los Lagos (10)',
    'Los Ríos (14)',
    'Magallanes y de la Antártica Chilena (12)',
    'Maule (7)',
    'Metropolitana de Santiago (13)',
    'Tarapacá (1)',
    'Valparaíso (5)',
    'Ñuble (16)',
  ],
  CA: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ],
  US: [
    'AA',
    'AE',
    'AP',
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
  ],
  AU: [
    'Australian Capital Territory',
    'Jervis Bay Territory',
    'New South Wales',
    'Northern Territory',
    'Queensland',
    'South Australia',
    'Tasmania',
    'Victoria',
    'Western Australia',
  ],
  BR: [
    'Acre',
    'Alagoas',
    'Amapá',
    'Amazonas',
    'Bahia',
    'Ceará',
    'Distrito Federal',
    'Espírito Santo',
    'Goiás',
    'Maranhão',
    'Mato Grosso',
    'Mato Grosso do Sul',
    'Minas Gerais',
    'Paraná',
    'Paraíba',
    'Pará',
    'Pernambuco',
    'Piauí',
    'Rio Grande do Norte',
    'Rio Grande do Sul',
    'Rio de Janeiro',
    'Rondônia',
    'Roraima',
    'Santa Catarina',
    'Sergipe',
    'São Paulo',
    'Tocantins',
  ],
};

const STORE_TYPE = [() => i18n('No'), () => i18n('Yes')] as const;

const STORE_CATEGORY = [
  () => i18n('Apparel'),
  () => i18n('Automotive'),
  () => i18n('Baby & Kids'),
  () => i18n('Beauty'),
  () => i18n('Convenience'),
  () => i18n('Discount & Thrift'),
  () => i18n('Electronics'),
  () => i18n('Entertainment'),
  () => i18n('Footwear'),
  () => i18n('Grocery'),
  () => i18n('Handbags & Fashion Accessories'),
  () => i18n('Home'),
  () => i18n('Jewelry'),
  () => i18n('Mobile Accessories & Repair'),
  () => i18n('Office Products'),
  () => i18n('Patio, Lawn, & Garden'),
  () => i18n('Personal Care & Wellness'),
  () => i18n('Pets'),
  () => i18n('Sports & Outdoor'),
  () => i18n('Tobacco'),
  () => i18n('Toys & Games'),
  () => i18n('Vitamins & Sports Nutrition'),
  () => i18n('Watches'),
  () => i18n('Other'),
] as const;

const DISCOVERY_METHOD = [
  () => i18n('Facebook'),
  () => i18n('Google'),
  () => i18n('Blog'),
  () => i18n('YouTube'),
  () => i18n('TV'),
  () => i18n('Direct mailing / Flyer'),
  () => i18n('Sales representative phone call'),
  () => i18n('Sales representative in-person visit'),
  () => i18n('Wish user'),
  () => i18n('Referred by another store'),
  () => i18n('Other'),
];

const DISCOVERY_METHODS = {
  FACEBOOK: 0,
  GOOGLE: 1,
  BLOG: 2,
  YOUTUBE: 3,
  TV: 4,
  FLYER: 5,
  SALES_REP_PHONE_CALL: 6,
  SALES_REP_VISIT: 7,
  WISH_USER: 8,
  REFERRED: 9,
} as const;

export const UTM_SOURCE_TO_DISCOVERY_METHODS: {
  [utmSource: string]: number;
} = {
  facebook: DISCOVERY_METHODS.FACEBOOK,
  facebookpage: DISCOVERY_METHODS.FACEBOOK,
  google: DISCOVERY_METHODS.GOOGLE,
  blog: DISCOVERY_METHODS.BLOG,
  youtube: DISCOVERY_METHODS.YOUTUBE,
  tv: DISCOVERY_METHODS.TV,
  local_call: DISCOVERY_METHODS.SALES_REP_PHONE_CALL,
} as const;

export const getCountryNames = () =>
  Object.values(COUNTRY_CODE_TO_NAME)
    .map(name => name())
    .sort();

export const getCountryCodeToName = () => {
  const countryCodeToName: { [countryCode: string]: string } = {};
  for (const [countryCode, name] of Object.entries(COUNTRY_CODE_TO_NAME)) {
    countryCodeToName[countryCode] = name();
  }
  return countryCodeToName;
};

export const getCountryNameToCode = () => {
  const countryNameToCode: { [countryName: string]: string } = {};
  for (const [countryCode, name] of Object.entries(COUNTRY_CODE_TO_NAME)) {
    countryNameToCode[name()] = countryCode;
  }
  return countryNameToCode;
};

export const getCountryStateMapping = () => COUNTRY_STATE_MAPPING;

export const getStoreTypes = () => STORE_TYPE.map(type => type());
export const getStoreCategories = () =>
  STORE_CATEGORY.map(category => category());
export const getDiscoveryMethods = () =>
  DISCOVERY_METHOD.map(method => method());
