import * as Sentry from '@sentry/browser';

const initSentry = () => {
  Sentry.init({
    dsn: `http://stub@${window.location.hostname}/sentry-proxy/1`,
    release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
    beforeSend(event, hint) {
      // if an error is some weird object thrown by 3rd party scripts, we ignore it
      // (in javascript you can throw anything, not limited to `Error` object,
      // we've observed some scripts throwing `undefined` and string 'Timeout' very frequently)
      switch (hint?.originalException) {
        case null:
        case undefined:
        case '':
        case 'Timeout':
        case 'Timeout (n)':
        case 'Timeout (h)':
          return null;
        default:
      }

      // if an error is thrown by axios we ignore it
      // because Sentry is flooded by axios errors now
      // @ts-ignore
      if (hint.originalException?.isAxiosError) {
        return null;
      }

      return event;
    },
    allowUrls: [
      /wish\.com/,
      /contextlogic\.com/,
      /wishlocal\.com/,
      /service\.consul/,
    ],
    autoSessionTracking: false,
    ignoreErrors: ['Object Not Found Matching Id:'],
  });
};

export default initSentry;
