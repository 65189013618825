export const LANDING_PAGE_ROUTE = '/';
export const SIGNUP_PAGE_ROUTE = '/signup';
export const SINGLE_SIGNUP_PAGE_ROUTE = '/single-signup';
export const MERCHANT_TERMS_ROUTE =
  'https://merchant.wish.com/terms-of-service';

export const RETAILER_TERMS_ROUTE = '/terms';
export const RETURNS_GUIDELINES_ROUTE = '/returns-guidelines';
export const OPERATING_GUIDELINES_ROUTE = '/operating-guidelines';
export const PICKUP_GUIDELINES_ROUTE = '/pickup-guidelines';
export const REFERRAL_TERMS_ROUTE = '/invite/terms';

export const COMMON_HELP_ROUTES = [
  RETAILER_TERMS_ROUTE,
  RETURNS_GUIDELINES_ROUTE,
  OPERATING_GUIDELINES_ROUTE,
  PICKUP_GUIDELINES_ROUTE,
  REFERRAL_TERMS_ROUTE,
];
