import { v4 as uuid } from 'uuid';
import { isDesktop, isMobile } from 'react-device-detect';
import store from 'app/store';

/* Actions */
import {
  setLocale as setAppLocale,
  setCountryCode as setAppCountryCode,
  setEnv as setAppEnv,
} from 'app/appSlice';
import {
  setExperiments as setAppExperiments,
  setDeciderKeys as setAppDeciderKeys,
} from 'app/experimentSlice';
import { setMarketingAttributes, setGoogleClickID } from 'app/marketingSlice';

/* Services */
import GetInitDataService from 'services/GetInitData';
import {
  LoadExperimentDataService,
  LoadDeciderKeyService,
} from 'services/LoadExperimentData';

/* Constants */
import {
  DEFAULT_LOCALE,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_ENV,
  XSRF_TOKEN,
  BLUE_VISITOR_UUID,
} from 'constants/AppConstants';

/* Utils */
import { getCookie, setCookie } from 'utils/Cookie';
import { setLocaleMap, isValidLocale } from 'utils/i18n';
import { isCountryValid } from 'utils/CountryValidator';
import {
  setSessionStorage,
  getSessionStorage,
  setSessionStorageIfNotExist,
} from 'utils/SessionStorage';

export const setVisitorUUID = () => {
  setSessionStorageIfNotExist(BLUE_VISITOR_UUID, uuid());
};

export const loadExperimentData = async () => {
  const visitorUUID = getSessionStorage(BLUE_VISITOR_UUID);
  if (visitorUUID === null) {
    return;
  }

  try {
    let bucketNames = {};
    let deciderKeyResults = {};
    const loadExperiments = async () => {
      const result = await LoadExperimentDataService(visitorUUID);
      bucketNames = result.bucket_names;
      store.dispatch(setAppExperiments(bucketNames));
    };
    const loadDeciderKeys = async () => {
      const result = await LoadDeciderKeyService();
      deciderKeyResults = result.results;
      store.dispatch(setAppDeciderKeys(deciderKeyResults));
    };
    await Promise.all([loadExperiments(), loadDeciderKeys()]);
    setCookie(
      'experiment_tags',
      JSON.stringify({
        ...bucketNames,
        ...deciderKeyResults,
      }),
    );
  } catch (error) {
    // error
  }
};

export const setInitData = async () => {
  let serverLocale: string | undefined;
  let countryCode: string | undefined;
  let serverEnv: string | undefined;
  try {
    const {
      locale,
      country,
      environment,
      xsrf_token: xsrfToken,
    } = await GetInitDataService();

    if (locale && isValidLocale(locale.substr(0, 2))) {
      serverLocale = locale.substr(0, 2);
    }
    if (country !== undefined && isCountryValid(country)) {
      countryCode = country;
    }
    if (environment) {
      serverEnv = environment;
    }
    if (xsrfToken) {
      setSessionStorage(XSRF_TOKEN, xsrfToken);
      setCookie('_xsrf', xsrfToken);
    }
  } catch (error) {
    // error
  }
  const locale = serverLocale || DEFAULT_LOCALE;

  store.dispatch(setAppLocale(locale));
  store.dispatch(setAppCountryCode(countryCode || DEFAULT_COUNTRY_CODE));
  store.dispatch(setAppEnv(serverEnv || DEFAULT_ENV));
  await setLocaleMap(locale);
};

export const setBaseCookieInfo = async () => {
  setCookie('is_desktop', isDesktop ? 'true' : 'false');
  setCookie('client', isMobile ? 'mobileweb' : 'web');
};

export const setMarketingAttributesIfAvailable = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const utmSource = searchParams.get('utm_source');
  const utmCampaign = searchParams.get('utm_campaign');
  const utmTerm = searchParams.get('utm_term');
  const utmContent = searchParams.get('utm_content');
  const utmKeyword = searchParams.get('utm_keyword');
  const utmDevice = searchParams.get('utm_device');
  const salesforceLeadID = searchParams.get('l');
  const signupBonusID = searchParams.get('sbid');
  const data = {
    utmSource,
    utmCampaign,
    utmTerm,
    utmContent,
    utmKeyword,
    utmDevice,
    salesforceLeadID,
    signupBonusID,
  };
  const cookieName = 'blue_marketing_attribution';

  if (utmSource) {
    store.dispatch(setMarketingAttributes(data));
    setCookie(cookieName, JSON.stringify(data));
  } else {
    const existingMarketingAttributes = getCookie(cookieName);
    if (existingMarketingAttributes) {
      store.dispatch(
        setMarketingAttributes(JSON.parse(existingMarketingAttributes)),
      );
    }
  }
};

export const setGoogleClickIDIfAvailable = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const googleClickID = searchParams.get('gclid');
  const cookieName = 'wish_local_gclid';

  if (googleClickID) {
    store.dispatch(setGoogleClickID(googleClickID));
    setCookie(cookieName, googleClickID);
  } else {
    const existingGCLID = getCookie(cookieName);
    if (existingGCLID) {
      store.dispatch(setGoogleClickID(existingGCLID));
    }
  }
};
