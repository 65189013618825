/* Utils */
import { i18n } from 'utils/i18n';

/* Types */
import type { StoreHours } from 'types/MultiScreenSignupTypes';

export const SUPPORTED_HOUR_SETS = 2;
export const HOURS_SET_0 = 0;
export const HOURS_SET_1 = 1;

export const WEEKDAYS = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
} as const;

export const DAYS_OF_THE_WEEK = [
  { name: () => i18n('Monday'), value: WEEKDAYS.MONDAY },
  { name: () => i18n('Tuesday'), value: WEEKDAYS.TUESDAY },
  { name: () => i18n('Wednesday'), value: WEEKDAYS.WEDNESDAY },
  { name: () => i18n('Thursday'), value: WEEKDAYS.THURSDAY },
  { name: () => i18n('Friday'), value: WEEKDAYS.FRIDAY },
  { name: () => i18n('Saturday'), value: WEEKDAYS.SATURDAY },
  { name: () => i18n('Sunday'), value: WEEKDAYS.SUNDAY },
];

export const MinuteRoundDirection = {
  DOWN: 0,
  UP: 1,
} as const;

export const googleDayIndexToBlueDayIndex = (googleDayIndex: number) => {
  // Converting from Google's indexing that starts with Sunday = 0
  // to Wish Blue indexing which starts with Monday = 0.
  return googleDayIndex === 0 ? 6 : googleDayIndex - 1;
};

export const googleTimeToSeconds = (
  googleTime: string,
  minuteRoundDirection?: Values<typeof MinuteRoundDirection>,
) => {
  let hoursPart = parseInt(googleTime.slice(0, 2), 10);
  let minutesPart = parseInt(googleTime.slice(2, 4), 10);
  if (minuteRoundDirection === MinuteRoundDirection.DOWN) {
    if (minutesPart < 30) {
      minutesPart = 0;
    } else {
      minutesPart = 30;
    }
  } else if (minuteRoundDirection === MinuteRoundDirection.UP) {
    if (minutesPart > 0 && minutesPart <= 30) {
      minutesPart = 30;
    } else if (minutesPart > 30) {
      hoursPart = (hoursPart + 1) % 24;
      minutesPart = 0;
    }
  }
  return 3600 * hoursPart + 60 * minutesPart;
};

export const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const hourString = String(hours).padStart(2, '0');
  const minuteString = String(minutes).padStart(2, '0');
  return `${hourString}:${minuteString}`;
};

export const getAPIStoreHours = (storeHours: StoreHours) => {
  const hoursList: string[] = [];
  DAYS_OF_THE_WEEK.forEach(({ value: dayIndex }) => {
    const hoursForDay = storeHours[dayIndex] || {};

    const formattedHoursListForDay: string[] = [];
    for (
      let setOfHoursIndex = 0;
      setOfHoursIndex < SUPPORTED_HOUR_SETS;
      setOfHoursIndex++
    ) {
      const hours = hoursForDay[setOfHoursIndex] || {};
      if (hours.start && hours.end) {
        const formattedHours = `${hours.start} - ${hours.end}`;
        formattedHoursListForDay.push(formattedHours);
      }
    }
    if (formattedHoursListForDay.length > 0) {
      const hoursForDayString = formattedHoursListForDay.join(',');
      hoursList.push(hoursForDayString);
    } else {
      hoursList.push('Closed');
    }
  });
  return hoursList;
};
