export const UA_PROPERTY_ID = 'UA-135607950-1';
export const GA4_PROPERTY_ID = 'G-FFCFHSCFCN';
// this group name contains both UA_PROPERTY_ID and GA4_PROPERTY_ID
export const GA_GROUP_NAME = 'analytics';
export const BLUE_SIGNUP_ID = 'AW-729780783/F9s5CImvtKMBEK-k_tsC';
export const BLUE_RETAIL_SIGNUP_ID = 'AW-729780783/9EwECM26tKMBEK-k_tsC';
export const OLD_BLUE_SIGNUP_CONVERSION = 'AW-729780783/6ftBCIWrtKMBEK-k_tsC';

export const EVENT_CATEGORY = {
  LANDING_PAGE: 'landing_page',
  SIGNUP_PAGE: 'signup_page',
} as const;

export const EVENT_ACTION = {
  PAGE_SCROLL: 'page_scroll',
  BUTTON_CLICK: 'button_click',
  VIDEO_PLAY: 'video_play',
  CONVERSION: 'conversion',
  SIGNUP: 'sign_up',
  GENERATE_LEAD: 'generate_lead',
  FAQ_CLICK: 'faq_click',
} as const;

export const BUTTON_LABEL = {
  CALL_ICON: 'call_icon',
} as const;

export const SIGNUP_BUTTON_LABEL = {
  HEADER: 'header_signup',
  HERO_IMAGE: 'hero_image_signup',
  FOOTER: 'footer_signup',
  OFFERINGS: 'offerings_signup',
} as const;

export const VIDEO_LABEL = {
  OVERVIEW: 'overview',
  HERO_IMAGE: 'hero_image',
} as const;

export const LEAD_LABEL = {
  PHONE_NUMBER: 'phone_number',
  EMAIL: 'email',
} as const;

// For more information about event parameters,
// see https://developers.google.com/analytics/devguides/collection/gtagjs/events.
export const sendGAEvent = (
  measurementID: string,
  action: Values<typeof EVENT_ACTION>,
  params?: {
    event_category?: string;
    event_label?: string;
    value?: number;
    [param: string]: string | number | undefined;
  },
) => {
  if (window.gtag) {
    window.gtag('event', action, {
      send_to: measurementID,
      ...params,
    });
  }
};
