export const SHOW_SIGNUP_BONUS_EXP = 'wishlocal_web_show_signup_bonus';
export const LANDING_PAGE_SHOW_CALL = 'landing_page_show_call_icon';
export const SHOW_REFERRAL_FIELD = 'wishlocal_web_show_referral_field';
export const ALLOW_RETAIL_SIGNUP_ONLY = 'local_only_retail_signup_allowed';
export const USE_CS_STORE_TYPE = 'local_use_cs_store_type';

export const EXPERIMENTS_IN_USE = [
  SHOW_SIGNUP_BONUS_EXP,
  LANDING_PAGE_SHOW_CALL,
  SHOW_REFERRAL_FIELD,
] as const;

export const DECIDER_KEYS_IN_USE = [
  ALLOW_RETAIL_SIGNUP_ONLY,
  USE_CS_STORE_TYPE,
] as const;
