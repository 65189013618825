import axios from 'axios';
import qs from 'qs';

export type CallOptionsType = {
  readonly callMonolith: boolean;
};

const call = async (
  endpoint: string,
  data: any = {},
  { callMonolith }: CallOptionsType = { callMonolith: false },
) => {
  const resp = await axios.post(
    callMonolith ? endpoint : `/v1/${endpoint}`,
    callMonolith ? qs.stringify(data, { arrayFormat: 'brackets' }) : data,
    {
      xsrfCookieName: '_xsrf', // default
      xsrfHeaderName: 'X-XSRFToken', // default
      headers: {
        'Content-Type': callMonolith
          ? 'application/x-www-form-urlencoded'
          : 'application/json',
      },
    },
  );
  return resp;
};

export default call;
