import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* Types */
import type { AppState } from 'app/store';

/* Selectors */
import { getShowExperiment } from 'app/experimentSlice';

/* Constants */
import { UTM_SOURCE_TO_DISCOVERY_METHODS } from 'constants/SignupFormConstants';
import { SHOW_SIGNUP_BONUS_EXP } from 'constants/ExperimentConstants';

type StateType = {
  utmSource?: string | null;
  utmContent?: string | null;
  utmCampaign?: string | null;
  utmTerm?: string | null;
  utmKeyword?: string | null;
  utmDevice?: string | null;
  salesforceLeadID?: string | null;
  googleClickID?: string | null;
  signupBonusID?: string | null;
  formattedSignupBonusAmount: string;
};

const initialState: StateType = {
  utmSource: null,
  utmContent: null,
  utmCampaign: null,
  utmTerm: null,
  utmKeyword: null,
  utmDevice: null,
  salesforceLeadID: null,
  googleClickID: null,
  signupBonusID: null,
  formattedSignupBonusAmount: '',
};

export const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    setMarketingAttributes: (
      state,
      action: PayloadAction<{
        utmSource?: string | null;
        utmContent?: string | null;
        utmCampaign?: string | null;
        utmTerm?: string | null;
        utmKeyword?: string | null;
        utmDevice?: string | null;
        salesforceLeadID?: string | null;
        signupBonusID?: string | null;
      }>,
    ) => {
      const {
        utmSource,
        utmCampaign,
        utmTerm,
        utmContent,
        utmKeyword,
        utmDevice,
        salesforceLeadID,
        signupBonusID,
      } = action.payload;
      state.utmSource = utmSource;
      state.utmCampaign = utmCampaign;
      state.utmTerm = utmTerm;
      state.utmContent = utmContent;
      state.utmKeyword = utmKeyword;
      state.utmDevice = utmDevice;
      state.salesforceLeadID = salesforceLeadID;
      state.signupBonusID = signupBonusID;
    },
    setGoogleClickID: (state, action: PayloadAction<string>) => {
      state.googleClickID = action.payload;
    },
    setFormattedSignupBonusAmount: (state, action: PayloadAction<string>) => {
      state.formattedSignupBonusAmount = action.payload;
    },
  },
});

// Actions
export const {
  setMarketingAttributes,
  setGoogleClickID,
  setFormattedSignupBonusAmount,
} = marketingSlice.actions;

// Selectors
export const getSignupBonusID = (state: AppState) =>
  state.marketing.signupBonusID;

export const getformattedSignupBonusAmount = (state: AppState) =>
  state.marketing.formattedSignupBonusAmount;

export const getSignupBonusFormattedAmount = (state: AppState) => {
  const isBonusEnabled = getShowExperiment(state, SHOW_SIGNUP_BONUS_EXP);
  const signupBonusID = getSignupBonusID(state);
  const formattedSignupBonusAmount = getformattedSignupBonusAmount(state);
  if (isBonusEnabled && signupBonusID && formattedSignupBonusAmount) {
    return formattedSignupBonusAmount;
  }
  return '';
};

export const getUTMSource = (state: AppState) => state.marketing.utmSource;

export const getDiscoveryMethod = (state: AppState) => {
  const utmSource = getUTMSource(state);
  if (!utmSource) {
    return undefined;
  }
  return UTM_SOURCE_TO_DISCOVERY_METHODS[utmSource.toLowerCase()];
};

// Reducer
const marketingReducer = marketingSlice.reducer;
export default marketingReducer;
