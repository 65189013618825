/* Utils */
import { i18n } from 'utils/i18n';

/* Routes */
import {
  RETAILER_TERMS_ROUTE,
  RETURNS_GUIDELINES_ROUTE,
  PICKUP_GUIDELINES_ROUTE,
  OPERATING_GUIDELINES_ROUTE,
  REFERRAL_TERMS_ROUTE,
} from 'constants/RouteConstants';

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_COUNTRY_CODE = 'US';
export const DEFAULT_ENV = 'prod';

export const BREAK_POINTS = {
  extraSmall: 400, // px
  small: 600, // px
  medium: 768, // px
  large: 1060, // px
  extraLarge: 1366, // px
} as const;

export const BREAK_POINTS_HEIGHT = {
  medium: 810, // px
} as const;

export const OVERVIEW_YOUTUBE_VIDEO_ID = 'gqn2PRShoQw';

export const MONOLITH_ENV = ['prod', 'stage'];

export const BLUE_VISITOR_UUID = 'blue_visitor_uuid';
export const XSRF_TOKEN = 'xsrf_token';

export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.wish.wishblue&hl=en_US';

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/wish-local-for-partner-stores/id1460406221';

const FIELD_TO_RELATIVE_ROUTES = {
  RETAILER_TERMS_OF_USE: RETAILER_TERMS_ROUTE,
  RETURNS_GUIDELINES: RETURNS_GUIDELINES_ROUTE,
  PICKUP_GUIDELINES: PICKUP_GUIDELINES_ROUTE,
  OPERATING_GUIDELINES: OPERATING_GUIDELINES_ROUTE,
  REFERRAL_TERMS_OF_USE: REFERRAL_TERMS_ROUTE,
} as const;

export const HELP_NAV_FIELD_TO_ROUTE = {
  ...FIELD_TO_RELATIVE_ROUTES,
} as const;

export const getNavName = (navField: string): string => {
  const HELP_NAV_NAMES = {
    [HELP_NAV_FIELD_TO_ROUTE.RETAILER_TERMS_OF_USE]: i18n(
      'Wish Local Retailer Terms & Conditions',
    ),
    [HELP_NAV_FIELD_TO_ROUTE.RETURNS_GUIDELINES]: i18n(
      'Wish Local Returns Guidelines',
    ),
    [HELP_NAV_FIELD_TO_ROUTE.REFERRAL_TERMS_OF_USE]: i18n(
      'Wish Local Retailer Referral Terms of Use',
    ),
    [HELP_NAV_FIELD_TO_ROUTE.PICKUP_GUIDELINES]: i18n(
      'Wish Local Pickup Guidelines',
    ),
    [HELP_NAV_FIELD_TO_ROUTE.OPERATING_GUIDELINES]: i18n(
      'Wish Local Operating Guidelines',
    ),
  } as const;
  return HELP_NAV_NAMES[navField as keyof typeof HELP_NAV_NAMES];
};
