/* Types */
import type { Metric } from 'web-vitals';

/* Constants */
import { BLUE_VISITOR_UUID } from 'constants/AppConstants';

/* Utils */
import call from 'utils/Api';
import { getSessionStorage } from 'utils/SessionStorage';

const ReportWebVitalsService = async (metric: Metric) => {
  try {
    await call('analytics/web_vitals', {
      ...metric,
      value: metric.name === 'CLS' ? metric.value * 1000 : metric.value,
      entries: JSON.stringify(
        metric.entries.map(({ name, startTime, duration, entryType }) => ({
          name,
          startTime,
          duration,
          entryType,
        })),
      ),
      visitor_uuid: getSessionStorage(BLUE_VISITOR_UUID),
    });
  } catch (e) {
    // error
  }
};

export default ReportWebVitalsService;
