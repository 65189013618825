import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/* Constants */
import {
  DEFAULT_LOCALE,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_ENV,
  OVERVIEW_YOUTUBE_VIDEO_ID,
} from 'constants/AppConstants';

/* Types */
import type { AppState } from 'app/store';

type StateType = {
  locale: string;
  countryCode: string;
  environment: string;
};

const initialState: StateType = {
  locale: DEFAULT_LOCALE,
  countryCode: DEFAULT_COUNTRY_CODE,
  environment: DEFAULT_ENV,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },
    setEnv: (state, action: PayloadAction<string>) => {
      state.environment = action.payload;
    },
  },
});

// Actions
export const { setLocale, setCountryCode, setEnv } = appSlice.actions;

// Selectors
export const getLocale = (state: AppState) => state.app.locale;
export const getCountryCode = (state: AppState) => state.app.countryCode;
export const getOverviewVideoURL = (_: AppState) => {
  return `https://www.youtube-nocookie.com/embed/${OVERVIEW_YOUTUBE_VIDEO_ID}?modestbranding=1&rel=0&autoplay=1&enablejsapi=1&cc_load_policy=1&origin=http://wishlocal.com`;
};
export const getEnv = (state: AppState) => state.app.environment;

// Reducer
const appReducer = appSlice.reducer;
export default appReducer;
