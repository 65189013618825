import styled, { css, keyframes } from 'styled-components';
import { FlexCenterChildren } from 'styles/Mixins';
import { assignIfDefined } from 'styles/Utils';

export const Wrapper = styled.div<{ margin?: string }>`
  min-width: 70px;
  ${FlexCenterChildren};
  ${assignIfDefined('margin')};
`;

const BounceDelay = keyframes`
  0%, 90%, 100% {
    transform: scale(0);
  } 50% {
    transform: scale(1.0);
  }
`;

const BounceDelayAnimationRule = css`
  ${BounceDelay} 1s infinite ease-in-out both
`;

export const Dot = styled.div<{ [props: string]: any }>`
  width: 8px;
  height: 8px;
  margin: 0 2px;
  border-radius: 100%;
  background-color: ${({ theme, color }) => color || theme.darkCobalt};
  animation: ${BounceDelayAnimationRule};
  animation-delay: ${({ delay }) => {
    if (delay === 1) {
      return '-0.16s';
    } else if (delay === 2) {
      return '0s';
    } else {
      return '-0.32s';
    }
  }};
`;
