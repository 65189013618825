/* Utils */
import call from 'utils/Api';

/* Constants */
import {
  EXPERIMENTS_IN_USE,
  DECIDER_KEYS_IN_USE,
} from 'constants/ExperimentConstants';

type LoadExpDataServiceType = {
  response_code: number;
  bucket_names: {
    [ExperimentName: string]: string;
  };
};

type LoadDeciderKeyServiceType = {
  response_code: number;
  results: {
    [DeciderKey: string]: boolean;
  };
};

export const LoadExperimentDataService = async (
  visitorUUID: string,
): Promise<LoadExpDataServiceType> => {
  const { data } = await call('experiment/bulk_get', {
    user_id: visitorUUID,
    experiment_names: EXPERIMENTS_IN_USE,
  });
  return data;
};

export const LoadDeciderKeyService =
  async (): Promise<LoadDeciderKeyServiceType> => {
    const { data } = await call('experiment/bulk_get_decider_keys', {
      decider_keys: DECIDER_KEYS_IN_USE,
    });
    return data;
  };
